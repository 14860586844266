import { useEffect, useState, useContext } from "react";
import { carService } from "../../Services/CarService";
import BootstrapTable from "react-bootstrap-table-next";
import CardWrap from "../Common/CardWrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loader from "../Common/Loader";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../Contexts/GlobalContext";
import { AuthContext } from "../../Contexts/AuthContext";
import { roles } from "../../Config/Roles";
import DeleteModal from "../Common/DeleteModal";
import ResultModal from "../Common/ResultModal";
import { companyService } from "../../Services/CompanyService";
import Select from "react-select";

const CarList = () => {
  const { t: translator } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const { isMobile } = useContext(GlobalContext);
  const { roleId } = useContext(AuthContext);
  const { superadmin, admin } = roles;
  const [update, setUpdate] = useState(new Date());
  const [companies, setCompanies] = useState([]);

  const [isPaid, setIsPaid] = useState(false);
  const [isExported, setIsExported] = useState(false);
  const [isArrived, setIsArrived] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    item: null,
    show: false,
  });
  const [resultModal, setResultModal] = useState({
    show: false,
    title: "",
    text: "",
  });
  const [search, setSearch] = useState({
    companyId: 0,
    isArrived: "",
    isPaid: "",
    isExported: "",
    locationId:0
  });
  const options = [
    { value: 0, label: "All locations" },
   { value: 1, label: "Bardney" },
   { value: 2, label: "Caythorpe" },
 ];
  useEffect(() => {
    Promise.all([
      carService.getCars(
        search.companyId,
        search.isPaid,
        search.isExported,
        search.isArrived,
        search.locationId
      ),
      companyService.getCompanies(),
    ])
      .then((res) => {
        setCars(res[0].data.data);
        var list = res[1].data.data.map((i) => ({
          value: i.id,
          label: i.name,
        }));
        list.unshift({
          value: 0,
          label: "All",
        });
        setCompanies(list);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [update, search]);

  const handleToAdd = () => {
    history.push("/add-car");
  };

  const rowEvents = {
    onClick: (e, row) => {
      e.preventDefault();
      if (e.target.classList.contains("pe-7s-trash")) {
        setDeleteModal({ item: row.id, show: true });
        return;
      }
      if (e.target.classList.contains("action2")) {
        history.push(`/update-invoice/${row.invoiceId}`);
        return;
      }
      history.push(`/update-car/${row.id}`);
    },
  };

  const { SearchBar } = Search;

  function actionFormatter(cell, row) {
    return (
      <div className="action">
        <i
          className="pe-7s-trash"
          style={{ color: "#c00", fontWeight: "bold", fontSize: 15 }}
        ></i>
      </div>
    );
  }
  function linkFormatter(cell, row) {
    return row.invoiceId != null ? (
      <a className="action2" style={{ display: "block" }}>
        {row.invoiceNumber}
      </a>
    ) : null;
  }
  function successFormatter(cell, row) {
    return cell == false ? null : (
      <div className="action">
        <i
          className="pe-7s-check"
          style={{ color: "#029688", fontWeight: "bold", fontSize: 15 }}
        ></i>
      </div>
    );
  }
  const columns = [
    {
      dataField: "id",
      text: "",
      formatter: actionFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
    },
    {
      dataField: "name",
      text: `${translator("CarTableName")}`,
      sort: true,
    },
    {
      dataField: "regNum",
      text: `${translator("CarTableRegNum")}`,
      sort: true,
    },
    {
      dataField: "lotNumber",
      text: `${translator("CarTableLotNumber")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "companyName",
      text: `${translator("CarTableCompany")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "subCustomer",
      text: `${translator("CarTableSubCompany")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "invoiceNumber",
      text: `${translator("CarTableInvoiceNumber")}`,
      formatter: linkFormatter,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "isPaid",
      formatter: successFormatter,
      text: `${translator("InvoiceFormPaid")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "isArrived",
      formatter: successFormatter,
      text: `${translator("InvoiceFormArrived")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
    {
      dataField: "isExported",
      formatter: successFormatter,
      text: `${translator("InvoiceFormExported")}`,
      sort: true,
      hidden: Boolean(isMobile),
    },
  ];

  const handleSubmitDeleteModal = () => {
    carService
      .deleteCar(deleteModal.item)
      .then((res) => {
        setDeleteModal({ item: null, show: false });
        res.data.success
          ? setResultModal({
              show: true,
              title: "Success",
              text: "SuccessText",
            })
          : setResultModal({
              show: true,
              title: "Error",
              text: "ErrorText",
            });
      })
      .catch((err) => console.log(err));
  };

  return (
    <CardWrap title={translator("CarOverview")}>
      <ToolkitProvider keyField="id" data={cars} columns={columns} search>
        {(props) => (
          <>
            <div className="row">
              {roleId < 3 && (
                <div className="col-2 col-xs-12">
                  <Select
                    options={companies}
                    placeholder={translator("SelectCompany")}
                    onChange={function (event) {
                      setSearch({ ...search, companyId: event.value });
                      return event;
                    }}
                  />
                </div>
              )}

              <div className="col-2 col-xs-12">
                <SearchBar
                  {...props.searchProps}
                  placeholder={translator("Search")}
                />
              </div>
              <div className="col-2 col-xs-12">
                  <Select
                    options={options}
                    placeholder={translator("CarLocation")}
                    onChange={function (event) {
                      setSearch({ ...search, locationId: event.value });
                      return event;
                    }}
                  />
                </div>
              <div className="col-md-1">
                <input
                  type="checkbox"
                  checked={isPaid}
                  name="isPaid"
                  onClick={function (event) {
                    setIsPaid(!isPaid);
                    setSearch({ ...search, isPaid: event.target.checked });

                    return event;
                  }}
                />{" "}
                {translator("IsPaid")}
              </div>
              <div className="col-md-2">
                <input
                  type="checkbox"
                  name="isExported"
                  checked={isExported}
                  onClick={function (event) {
                    setIsExported(!isExported);
                    setSearch({ ...search, isExported: event.target.checked });

                    return event;
                  }}
                />{" "}
                {translator("IsExported")}
              </div>
              <div className="col-md-2">
                <input
                  type="checkbox"
                  name="isArrived"
                  checked={isArrived}
                  onChange={function (event) {
                    //console.log(event.target.checked);
                    setIsArrived(!isArrived);
                    setSearch({ ...search, isArrived: event.target.checked });

                    return event;
                  }}
                />{" "}
                {translator("IsArrived")}
              </div>
              {[superadmin, admin].includes(Number(roleId)) && (
                <div className="col-md-1">
                  <span style={{ float: "right" }}>
                    <Button variant="primary" onClick={handleToAdd}>
                      {isMobile ? (
                        <i className="pe-7s-plus"></i>
                      ) : (
                        translator("CarAddButton")
                      )}
                    </Button>
                  </span>
                </div>
              )}

              <hr />
            </div>

            {isLoading ? (
              <Loader />
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                noDataIndication={translator("NoDataIndication")}
                rowEvents={rowEvents}
                hover
              />
            )}
          </>
        )}
      </ToolkitProvider>
      <DeleteModal
        show={deleteModal.show}
        handleHide={() =>
          setDeleteModal({
            item: null,
            show: false,
          })
        }
        handleSubmit={handleSubmitDeleteModal}
      />
      <ResultModal
        show={resultModal.show}
        title={resultModal.title}
        text={resultModal.text}
        handleHide={() => setResultModal({ ...resultModal, show: false })}
        handleSubmit={() => {
          setUpdate(new Date());
          setResultModal({ show: false });
        }}
      />
    </CardWrap>
  );
};

export default CarList;
